import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home.vue";
import FoodSafety from "../views/food-safety/index.vue";
import DigitalInspection from "../views/digital-inspection/index.vue";
import Login from "../views/login.vue";
Vue.use(VueRouter);

const routes = [
	{
		path: "/test",
		name: "home",
		component: Home,
	},
	{
		path: "/",
		name: "home",
		component: FoodSafety,
	},
	{
		path: "/login",
		name: "Login",
		component: Login,
	},
	{
		path: "/safe",
		name: "FoodSafety",
		component: FoodSafety,
	},
	{
		path: "/check",
		name: "DigitalInspection",
		component: DigitalInspection,
	},
	{
		path: "/canteen",
		name: "canteen",
		component: () => import("@/views/canteen/index.vue"),
	},
	{
		path: "/device",
		name: "device-view",
		component: () => import("@/views/device-view/index.vue"),
	},
	{
		path: "/view/file",
		component: () => import("@/views/view-file/index.vue"),
		hidden: true
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
