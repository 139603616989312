import top from "../../components/chart/top.vue";
import footerCom from "../../components/chart/footer.vue";
import titleCom from "../../components/chart/title.vue";
import longTitleCom from "../../components/chart/long-title.vue";
import lineChart from "../../components/echart/line.vue";
import pieChart from "../../components/echart/pie.vue";
import pieTask from "../../components/echart/pie-task.vue";
import logout from "../../components/chart/logout.vue";
import radarChart from "../../components/echart/radar.vue";
import dayjs from "dayjs";
import * as echarts from "echarts";
import { logisticsCommand, inspectionProgress, rectifyRadar, rectifyStatistic, inspectionAnalyse2, improvementTable, improvementNightTing, shopTopRed, shopTopBlack, checkRecordOnTime, rulesList, rulesTableList,xjHead, xjTable,blackDetail,shopTopBlack2 } from "@/api/digital-inspection";
export default {
  components: { top, titleCom, lineChart, pieChart, radarChart, pieTask, longTitleCom, footerCom, logout },
  data() {
    return {
      redScore: 100,
      ruleTable: [],
      ruleDataScroll:[],
      currentTableTopRule:0,
      deviceItemTopRule: 0,
      transtionDetalyRule: "0.5s",
      isScrollRule: true,
      deviceScorllTimerRule: null,
      threeMinuteStartRule:null,
      ruleList: [],
      widthPix: 1,
      timer: null,
      // 后勤指挥巡检
      logisticsData: {
        userCount: 0,
        checkCount: 0,
        offLineCount: 0,
        onLineCount: 0,
      },
      // 本月食堂巡检进度
      inspectionProgressData: {
        offLineCount: 0,
        onLineCount: 0,
        shopCount: 0,
      },
      // 本月整改分布 --- 雷达图
      rectifyRadarData: {
        againCount: 0,
        completeCount: 0,
        failCount: 0,
        rectifyRadarCount: 0,
        unVerifyCount: 0,
        undoCount: 0,
      },
      // 本月整改分布右侧数据
      rectifyData: [
      ],
      rectifyDataScroll:[],
      currentTableTopRectify:0,
      deviceItemTopRectify: 0,
      transtionDetalyRectify: "0.5s",
      isScrollRectify: true,
      deviceScorllTimerRectify: null,
      threeMinuteStartRectify:null,
      //    本月食堂巡检分析
      shopDataMonth: [
      ],
      shopDataMonthScroll:[],
      currentTableTopMonth:0,
      deviceItemTopMonth: 0,
      transtionDetalyMonth: "0.5s",
      isScrollMonth: true,
      deviceScorllTimerMonth: null,
      threeMinuteStartMonth:null,
      // 近3月重点提升方向
      improvementData: [
        [
          {
            index: 0,
            problemTopicCategory: "卫生情况",
            tableTopicName: "卫生",
            rectifyCount: 0,
          },
          {
            index: 1,
            problemTopicCategory: "卫生情况",
            tableTopicName: "卫生",
            rectifyCount: 0,
          },
          {
            index: 2,
            problemTopicCategory: "卫生情况",
            tableTopicName: "卫生",
            rectifyCount: 0,
          },
          //   {
          //     index: 3,
          //     problemTopicCategory: "卫生情况",
          //     tableTopicName: "卫生",
          //     rectifyCount: 0,
          //   },
        ],
      ],
      // 红榜数据
      redData: [
      ],
      redDataScroll:[],
      currentTableTopRed:0,
      deviceItemTopRed: 0,
      transtionDetalyRed: "0.5s",
      isScrollRed: true,
      deviceScorllTimerRed: null,
      threeMinuteStartRed:null,
      // 黑榜数据
      blackData: [
      ],
      blackDataScroll:[],
      currentTableTopBlack:0,
      deviceItemTopBlack: 0,
      transtionDetalyBlack: "0.5s",
      isScrollBlack: true,
      deviceScorllTimerBlack: null,
      threeMinuteStartBlack:null,
      // 重点提升右侧图形表述
      improvementChartDescData: [],
      onTimeData: [
      ],
      onTimeDataScroll:[],
      currentTableTopTime:0,
      deviceItemTopTime: 0,
      transtionDetalyTime: "0.5s",
      isScrollTime: true,
      deviceScorllTimerTime: null,
      threeMinuteStartTime:null,
      // 巡检进度timer
      progressTimer: null,
      // 雷达图timer
      radarTimer: null,
      // 巡检分析timer
      analyseTimer: null,
      // 南丁timer
      nightTingTimer: null,
      timer2: null,
      showFilpper: false,
      flipperTimer: null,
      // 红黑榜
      redTimer: null,
      redTrue: false,
      blackTimer: null,
      blackTrue: false,
      ruleId: undefined,
      xjDiaShow: false,
      tableLoading:false,
      xjTableList: [
      ],
      xjheadList: [
        {
          label: "已巡检",
          num: 10,
          type: 1,
          isChoose: true,
        },
        {
          label: "未巡检",
          num: 20,
          type: 2,
          isChoose: false,
        },
      ],
      queryParams: {
        current: 1,
        size: 999,
        shopId: undefined,
      },
      xjLabel: "",
      total:0,
      dzgDiaShow: false,
      safeScoreShopList:[],
      safeScoreList: [],
      shopScoreParams: {
        shopId: 0,
        grade: 1,
        current: 1,
        size: 10,
      },
      isDzgClick:false,
      safeScoreShow:false,
      scoreChooseList: [
        {
          isChoose: true,
          problemCount: 13,
        },
        {
          isChoose: false,
          problemCount: 0,
        },
        {
          isChoose: false,
          problemCount: 13,
        },
      ],
    };
  },
  methods: {
    // 滚动
    getRulesTableList(ruleId) {
      this.ruleTable = [];
      this.ruleDataScroll = [];
      this.deviceItemTopRule = 0;
      this.transtionDetalyRule = '0s'
      if(this.deviceScorllTimerRule){
        clearInterval(this.deviceScorllTimerRule);
        this.deviceScorllTimerRule = null;
      }
      // 三秒开启滚动的定时器
      if(this.threeMinuteStartRule){
        clearInterval(this.threeMinuteStartRule);
        this.threeMinuteStartRule = null;
      }
      rulesTableList({ ruleId: ruleId }).then((res) => {
        // for (let i = 0; i < res.data.length; i += 5) {
        //   this.ruleTable.push(res.data.slice(i, i + 5));
        // }
        this.ruleTable = res.data;
        this.ruleDataScroll = JSON.parse(JSON.stringify(this.ruleTable));
        
        if(this.ruleTable.length>5){
          this.$nextTick(()=>{
            this.deviceItemTopRule = 0;
            // 刷新数据清空定时器。并且如果isScrollRule为false的话（代表鼠标在内容体内）  不开启滚动
            // 滚动的定时器
            if(this.isScrollRule){
              this.deviceScrollFnRule();
            }
          })
        }
      });
    },
    deviceTableEnter() {
      if(this.ruleTable.length<=5){
        return;
      }
      // 手标移入。自动滚动取消.显示滚动条
      this.isScrollRule = false;
      // 滚动的定时器
      if(this.deviceScorllTimerRule){
        clearInterval(this.deviceScorllTimerRule);
        this.deviceScorllTimerRule = null;
      }
      // 三秒开启滚动的定时器
      if(this.threeMinuteStartRule){
        clearInterval(this.threeMinuteStartRule);
        this.threeMinuteStartRule = null;
      }
      
      this.$nextTick(() => {
          const itemHeight =
              this.$refs["ruleitem"][0].offsetHeight;
          // 滚动的次数
          const scrollCnt = this.deviceItemTopRule / -itemHeight;
          // 当前显示
          let scrollCurrentIndex =
              scrollCnt > this.ruleTable.length
                  ? Number(
                        Math.round(scrollCnt / this.ruleTable) + 1
                    )
                  : Number(Math.round(scrollCnt) + 1);
          const maxLength = this.ruleTable.length;
          let scrollTop = 0 ;
          // 当滚动第七个到10的时候  显示最后六个
          if (
            scrollCurrentIndex >= maxLength -5  &&
            scrollCurrentIndex < maxLength-2
        ) {
          scrollTop = (maxLength - 6)  * itemHeight;
            this.$nextTick(() => {
                this.$refs["rulelist"].scrollTop = scrollTop;
            });
            // 滚动第一个或者是滚动到第10、11个。显示前六个
        } else if (scrollCurrentIndex == 1 || scrollCurrentIndex>=maxLength-2) {
            this.$refs["rulelist"].scrollTop = 0;
        } else {
              scrollTop = (scrollCurrentIndex - 1) * itemHeight;
              this.$nextTick(() => {
                  this.$refs["rulelist"].scrollTop = scrollTop;
              });
          }
      });
  },
  deviceTableLeave() {
      if(this.ruleTable.length<6){
        return;
      }
      if(this.threeMinuteStartRule){
        return;
      }
      // 手标移出 三秒开启滚动
      this.threeMinuteStartRule = setTimeout(()=>{
        this.currentTableTopRule = this.$refs["rulelist"].scrollTop;
        this.isScrollRule = true;
        this.ruleDataScroll = JSON.parse(JSON.stringify(this.ruleTable));
        this.$nextTick(()=>{
          this.deviceItemTopRule = -this.currentTableTopRule;
          this.transtionDetalyRule = '0s';
          this.deviceScrollFnRule();
        })
        
      },3000)
  },
  // 智慧设备概览滚动方法
  deviceScrollFnRule(){
    this.deviceScorllTimerRule = setInterval(() => {
        this.transtionDetalyRule = "0.5s";
        // 滚动到最后三个，需要将数据放置尾部。实现首尾相连
        this.$nextTick(() => {
            const itemHeight =
                this.$refs["ruleitemScroll"][0].offsetHeight;
            this.deviceItemTopRule -= itemHeight;
            const length = this.ruleDataScroll.length;
            const maxScorllLength = length - 6;
            if (this.deviceItemTopRule <= maxScorllLength * -itemHeight) {
                this.ruleDataScroll.push(...this.ruleTable);
            }
            const currentIndex = Math.round(
                this.deviceItemTopRule / -itemHeight
            );
            // 清空数据。
            if (currentIndex == this.ruleTable.length+1) {
                this.ruleDataScroll.splice(
                    0,
                    this.ruleTable.length
                );
                this.transtionDetalyRule = "0s";
                this.deviceItemTopRule = 0;
                setTimeout(() => {
                    this.deviceItemTopRule = -itemHeight;
                    this.transtionDetalyRule = "0.5s";
                }, 20);
            }
        });
    }, 2000);
  },

   // 本月食堂巡检分析
   // 本月食堂巡检分析
   getInspectionAnalyseData() {
    if(this.deviceScorllTimerMonth){
      clearInterval(this.deviceScorllTimerMonth);
      this.deviceScorllTimerMonth = null;
    }
    // 三秒开启滚动的定时器
    if(this.threeMinuteStartMonth){
      clearInterval(this.threeMinuteStartMonth);
      this.threeMinuteStartMonth = null;
    }
    this.shopDataMonth = [];
      this.shopDataMonthScroll = [];
      this.deviceItemTopMonth = 0;
      this.transtionDetalyMonth = '0s'
      
    inspectionAnalyse2().then((res) => {
      const data = res.data;
      const tableData = JSON.parse(JSON.stringify(data));
      tableData.forEach((e, index) => {
        e.index = index + 1;
      });
      
      this.shopDataMonth = tableData;
      this.shopDataMonthScroll = JSON.parse(JSON.stringify(tableData));
     
      if(this.shopDataMonth.length>=6){
        this.$nextTick(()=>{
          this.deviceItemTopMonth = 0;
          // 滚动的定时器
          
          if(this.isScrollMonth){
            this.deviceScrollFnMonth();
          }
        })
      }
      const category = data.map((e) => e.shopName);
      const onLineScore = data.map((e) => Number(e.onlineScore));
      const offLineScore = data.map((e) => Number(e.offlineScore));
      const series = [
        
        {
          name: "督导巡检",
          type: "bar",
          showBackground: true,
          backgroundStyle: {
            color: "rgba(43, 62, 81, 0.6)",
          },
          barGap: 1,
          barWidth: 10 * this.widthPix,
          label: {
            show: false, //开启显示
            position: "top", //在上方显示
            textStyle: {
              //数值样式
              color: "#D0DEEE",
              fontSize: this.widthPix * 11,
            },
            formatter: function (params) {
              //核心部分 formatter 可以为字符串也可以是回调

              return params.value;
            },
          },
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: " rgba(21,177,255,0.30)" },
              // { offset: 0.5, color: "#64C56E" },
              { offset: 1, color: " #15B5FF" },
            ]),
            
          },
          data: offLineScore,
        },
        {
          name: "视频巡检",
          type: "bar",
          showBackground: true,
          backgroundStyle: {
            color: "rgba(43, 62, 81, 0.6)",
          },
          barWidth: 10 * this.widthPix,
          label: {
            show: false, //开启显示
            position: "top", //在上方显示
            textStyle: {
              //数值样式
              color: "#15B5FF",
              fontSize: this.widthPix * 11,
            },
            formatter: function (params) {
              return params.value;
            },
          },
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "rgba(208,222,238,0.30)" },
              // { offset: 0.5, color: "#4F8DFF" },
              { offset: 1, color: "#D0DEEE" },
            ]),
          },
          data: onLineScore,
        },
      ];
      this.$refs.scoreLine.initChart(series, category);
      if (this.analyseTimer) {
        clearInterval(this.analyseTimer);
        this.analyseTimer = null;
      }
      this.analyseTimer = setInterval(() => {
        this.$refs.scoreLine.initChart(series, category);
      }, 60000);
    });
  },
deviceTableEnterMonth() {
  if(this.shopDataMonth.length<6){
    return;
  }
  // 手标移入。自动滚动取消.显示滚动条
  this.isScrollMonth = false;
  // 滚动的定时器
  if(this.deviceScorllTimerMonth){
    clearInterval(this.deviceScorllTimerMonth);
    this.deviceScorllTimerMonth = null;
  }
  // 三秒开启滚动的定时器
  if(this.threeMinuteStartMonth){
    clearInterval(this.threeMinuteStartMonth);
    this.threeMinuteStartMonth = null;
  }
  
  this.$nextTick(() => {
      const itemHeight =
          this.$refs["rowitem"][0].offsetHeight;
      // 滚动的次数
      const scrollCnt = this.deviceItemTopMonth / -itemHeight;
      // 当前显示
      let scrollCurrentIndex =
          scrollCnt > this.shopDataMonth.length
              ? Number(
                    Math.round(scrollCnt / this.shopDataMonth) + 1
                )
              : Number(Math.round(scrollCnt) + 1);
      const maxLength = this.shopDataMonth.length;
      let scrollTop = 0 ;
      if (
        scrollCurrentIndex >= maxLength -5  &&
        scrollCurrentIndex < maxLength-2
      ) {
        scrollTop = (maxLength - 6)  * itemHeight;
          this.$nextTick(() => {
              this.$refs["rowlist"].scrollTop = scrollTop;
          });
      } else if (scrollCurrentIndex == 1 || scrollCurrentIndex>=maxLength-2) {
          this.$refs["rowlist"].scrollTop = 0;
      } else {
          scrollTop = (scrollCurrentIndex - 1) * itemHeight;
          this.$nextTick(() => {
              this.$refs["rowlist"].scrollTop = scrollTop;
          });
      }
  });
},
deviceTableLeaveMonth() {
  if(this.shopDataMonth.length<6){
    return;
  }
  if(this.threeMinuteStartMonth){
    return;
  }
  // 手标移出 三秒开启滚动
  this.threeMinuteStartMonth = setTimeout(()=>{
    this.currentTableTopMonth = this.$refs["rowlist"].scrollTop;
    this.isScrollMonth = true;
    this.shopDataMonthScroll = JSON.parse(JSON.stringify(this.shopDataMonth));
    this.$nextTick(()=>{
      this.deviceItemTopMonth = -this.currentTableTopMonth;
      this.transtionDetalyMonth = '0s';
      this.deviceScrollFnMonth();
    })
  },3000)
},
// 智慧设备概览滚动方法
deviceScrollFnMonth(){
this.deviceScorllTimerMonth = setInterval(() => {
    this.transtionDetalyMonth = "0.5s";
    // 滚动到最后三个，需要将数据放置尾部。实现首尾相连
    this.$nextTick(() => {
        const itemHeight =
            this.$refs["rowitemScroll"][0].offsetHeight;
        this.deviceItemTopMonth -= itemHeight;
        const length = this.shopDataMonthScroll.length;
        const maxScorllLength = length - 6;
        if (this.deviceItemTopMonth <= maxScorllLength * -itemHeight) {
            this.shopDataMonthScroll.push(...this.shopDataMonth);
        }
        const currentIndex = Math.round(
            this.deviceItemTopMonth / -itemHeight
        );
        // 清空数据。
        if (currentIndex == this.shopDataMonth.length+1) {
            this.shopDataMonthScroll.splice(
                0,
                this.shopDataMonth.length
            );
            this.transtionDetalyMonth = "0s";
            this.deviceItemTopMonth = 0;
            setTimeout(() => {
                this.deviceItemTopMonth = -itemHeight;
                this.transtionDetalyMonth = "0.5s";
            }, 20);
        }
    });
}, 2000);
},

// 整改统计
getRectifyStatisticData() {
  this.rectifyData = [];
  this.rectifyDataScroll = []
  this.deviceItemTopRectify = 0;
  this.transtionDetalyRectify ='0s'
  if(this.deviceScorllTimerRectify){
    clearInterval(this.deviceScorllTimerRectify);
    this.deviceScorllTimerRectify = null;
  }
  // 三秒开启滚动的定时器
  if(this.threeMinuteStartRectify){
    clearInterval(this.threeMinuteStartRectify);
    this.threeMinuteStartRectify = null;
  }
  rectifyStatistic().then((res) => {
    const data = res.data;
    data.forEach((e, index) => {
      e.index = index + 1;
    });
    this.rectifyData = data;
    this.rectifyDataScroll = JSON.parse(JSON.stringify(this.rectifyData));
    
    if(this.rectifyData.length>9){
      this.$nextTick(()=>{
        this.deviceItemTopRectify = 0;
        // 滚动的定时器
      
        if(this.isScrollRectify){
          this.deviceScrollFnRectify();
        }
      })
    }
  });
},
deviceTableEnterRectify() {
  if(this.rectifyData.length<=9){
    return;
  }
  // 手标移入。自动滚动取消.显示滚动条
  this.isScrollRectify = false;
  // 滚动的定时器
  if(this.deviceScorllTimerRectify){
    clearInterval(this.deviceScorllTimerRectify);
    this.deviceScorllTimerRectify = null;
  }
  // 三秒开启滚动的定时器
  if(this.threeMinuteStartRectify){
    clearInterval(this.threeMinuteStartRectify);
    this.threeMinuteStartRectify = null;
  }
  
  this.$nextTick(() => {
      const itemHeight =
          this.$refs["pieitem"][0].offsetHeight;
      // 滚动的次数
      const scrollCnt = this.deviceItemTopRectify / -itemHeight;
      // 当前显示
      let scrollCurrentIndex =
          scrollCnt > this.rectifyData.length
              ? Number(
                    Math.round(scrollCnt / this.rectifyData) + 1
                )
              : Number(Math.round(scrollCnt) + 1);
      const maxLength = this.rectifyData.length;
      let scrollTop = 0 ;
      if (
          scrollCurrentIndex >= maxLength -8  &&
          scrollCurrentIndex < maxLength-4
      ) {
        scrollTop = (maxLength - 9)  * itemHeight;
          this.$nextTick(() => {
              this.$refs["pielist"].scrollTop = scrollTop;
          });
      } else if (scrollCurrentIndex == 1 || scrollCurrentIndex >= maxLength-4) {
          this.$refs["pielist"].scrollTop = 0;
      } else {
          scrollTop = (scrollCurrentIndex - 1) * itemHeight;
          this.$nextTick(() => {
              this.$refs["pielist"].scrollTop = scrollTop;
          });
      }
  });
},
deviceTableLeaveRectify() {
  if(this.rectifyData.length<8){
    return;
  }
  if(this.threeMinuteStartRectify){
    return;
  }
  // 手标移出 三秒开启滚动
  this.threeMinuteStartRectify = setTimeout(()=>{
    this.currentTableTopRectify = this.$refs["pielist"].scrollTop;
    this.isScrollRectify = true;
    this.rectifyDataScroll = JSON.parse(JSON.stringify(this.rectifyData));
    this.$nextTick(()=>{
      this.deviceItemTopRectify = -this.currentTableTopRectify;
      this.transtionDetalyRectify = '0s';
      this.deviceScrollFnRectify();
    })
  },3000)
},
deviceScrollFnRectify(){
this.deviceScorllTimerRectify = setInterval(() => {
    this.transtionDetalyRectify = "0.5s";
    // 滚动到最后三个，需要将数据放置尾部。实现首尾相连
    this.$nextTick(() => {
        const itemHeight =
            this.$refs["pieitemScroll"][0].offsetHeight;
        this.deviceItemTopRectify -= itemHeight;
        const length = this.rectifyDataScroll.length;
        const maxScorllLength = length - 9;
        if (this.deviceItemTopRectify <= maxScorllLength * -itemHeight) {
            this.rectifyDataScroll.push(...this.rectifyData);
        }
        const currentIndex = Math.round(
            this.deviceItemTopRectify / -itemHeight
        );
        // 清空数据。
        if (currentIndex == this.rectifyData.length+1) {
            this.rectifyDataScroll.splice(
                0,
                this.rectifyData.length
            );
            this.transtionDetalyRectify = "0s";
            this.deviceItemTopRectify = 0;
            setTimeout(() => {
                this.deviceItemTopRectify = -itemHeight;
                this.transtionDetalyRectify = "0.5s";
            }, 20);
        }
    });
}, 2000);
},

 // 红榜
 getRedData() {
  this.redData = []
  this.redDataScroll = []
  this.deviceItemTopRed = 0;
  this.transtionDetalyRed = '0s'
  if(this.deviceScorllTimerRed){
    clearInterval(this.deviceScorllTimerRed);
    this.deviceScorllTimerRed = null;
  }
  // 三秒开启滚动的定时器
  if(this.threeMinuteStartRed){
    clearInterval(this.threeMinuteStartRed);
    this.threeMinuteStartRed = null;
  }
  shopTopRed().then((res) => {
    this.redTrue = true;
    this.redData = res.data.sort((a, b) => (Number(a.score) < Number(b.score) ? 1 : -1));
    this.redData.forEach((e,index)=>{
      e.index = index+1
    })
    this.redDataScroll = JSON.parse(JSON.stringify(this.redData));
        
        if(this.redData.length>6){
          this.$nextTick(()=>{
            this.deviceItemTopRed = 0;
            // 刷新数据清空定时器。并且如果isScrollRed为false的话（代表鼠标在内容体内）  不开启滚动
            // 滚动的定时器
            
            if(this.isScrollRed){
              this.deviceScrollFnRed();
            }
          })
        }
   
  });
},
deviceTableEnterRed() {
  if(this.redData.length<=6){
    return;
  }
  // 手标移入。自动滚动取消.显示滚动条
  this.isScrollRed = false;
  // 滚动的定时器
  if(this.deviceScorllTimerRed){
    clearInterval(this.deviceScorllTimerRed);
    this.deviceScorllTimerRed = null;
  }
  // 三秒开启滚动的定时器
  if(this.threeMinuteStartRed){
    clearInterval(this.threeMinuteStartRed);
    this.threeMinuteStartRed = null;
  }
  
  this.$nextTick(() => {
      const itemHeight =
          this.$refs["rankitem"][0].offsetHeight;
      // 滚动的次数
      const scrollCnt = this.deviceItemTopRed / -itemHeight;
      // 当前显示
      let scrollCurrentIndex =
          scrollCnt > this.redData.length
              ? Number(
                    Math.round(scrollCnt / this.redData) + 1
                )
              : Number(Math.round(scrollCnt) + 1);
      const maxLength = this.redData.length;
      let scrollTop = 0 ;
      // 当滚动第七个到10的时候  显示最后六个
      if (
        scrollCurrentIndex >= maxLength -5  &&
        scrollCurrentIndex < maxLength-2
    ) {
      scrollTop = (maxLength - 6)  * itemHeight;
        this.$nextTick(() => {
            this.$refs["ranklist"].scrollTop = scrollTop;
        });
        // 滚动第一个或者是滚动到第10、11个。显示前六个
    } else if (scrollCurrentIndex == 1 || scrollCurrentIndex>=maxLength-2) {
        this.$refs["ranklist"].scrollTop = 0;
    } else {
          scrollTop = (scrollCurrentIndex - 1) * itemHeight;
          this.$nextTick(() => {
              this.$refs["ranklist"].scrollTop = scrollTop;
          });
      }
  });
},
deviceTableLeaveRed() {
  if(this.redData.length<=6){
    return;
  }
  if(this.threeMinuteStartRed){
    return;
  }
  // 手标移出 三秒开启滚动
  this.threeMinuteStartRed = setTimeout(()=>{
    this.currentTableTopRed = this.$refs["ranklist"].scrollTop;
    this.isScrollRed = true;
    this.redDataScroll = JSON.parse(JSON.stringify(this.redData));
    this.$nextTick(()=>{
      this.deviceItemTopRed = -this.currentTableTopRed;
      this.transtionDetalyRed = '0s';
      this.deviceScrollFnRed();
    })
    
  },3000)
},
// 智慧设备概览滚动方法
deviceScrollFnRed(){
this.deviceScorllTimerRed = setInterval(() => {
    this.transtionDetalyRed = "0.5s";
    // 滚动到最后三个，需要将数据放置尾部。实现首尾相连
    this.$nextTick(() => {
        const itemHeight =
            this.$refs["rankitemScroll"][0].offsetHeight;
        const length = this.redDataScroll.length;
        const maxScorllLength = length - 6;
        if (this.deviceItemTopRed <= (maxScorllLength) * -itemHeight) {
          this.transtionDetalyRed = "0s";
          this.deviceItemTopRed = 0;
          setTimeout(() => {
              // this.deviceItemTopRed = -itemHeight;
              this.transtionDetalyRed = "0.5s";
          }, 20);

        }else{
          this.deviceItemTopRed -= itemHeight;
        }

        // if (this.deviceItemTopRed <= maxScorllLength * -itemHeight) {
        //     this.redDataScroll.push(...this.redData);
        // }
        // const currentIndex = Math.round(
        //     this.deviceItemTopRed / -itemHeight
        // );
        // // 清空数据。
        // if (currentIndex == this.redData.length+1) {
        //     this.redDataScroll.splice(
        //         0,
        //         this.redData.length
        //     );
        //     this.transtionDetalyRed = "0s";
        //     this.deviceItemTopRed = 0;
        //     setTimeout(() => {
        //         this.deviceItemTopRed = -itemHeight;
        //         this.transtionDetalyRed = "0.5s";
        //     }, 20);
        // }
    });
}, 2000);
},

// 黑榜
getBlackData() {
  this.blackData = [];
  this.blackDataScroll = []
  this.deviceItemTopBlack = 0;
  this.transtionDetalyBlack = '0s'
  if(this.deviceScorllTimerBlack){
    clearInterval(this.deviceScorllTimerBlack);
    this.deviceScorllTimerBlack = null;
  }
  // 三秒开启滚动的定时器
  if(this.threeMinuteStartBlack){
    clearInterval(this.threeMinuteStartBlack);
    this.threeMinuteStartBlack = null;
  }
  shopTopBlack().then((res) => {
    this.blackData = res.data.sort((a, b) => (Number(a.problemCount) < Number(b.problemCount) ? 1 : -1));
    const problemCount = this.blackData.map((e) => Number(e.problemCount));
    const totalProblemCount = problemCount.reduce((pre, next) => pre + next);
    this.blackData.forEach((e,index) => {
      const score = Number(e.problemCount);
      e.rate = score==0?'0%':Math.round((score / totalProblemCount) * 100) + "%";
      e.index = index+1
    });
    this.scoreChooseList = [...this.blackData];
    this.scoreChooseList.forEach((e) => {
      e.isChoose = false;
    });
    this.blackDataScroll = JSON.parse(JSON.stringify(this.blackData));
      
      if(this.blackData.length>6){
        this.$nextTick(()=>{
          this.deviceItemTopBlack = 0;
          // 刷新数据清空定时器。并且如果isScrollBlack为false的话（代表鼠标在内容体内）  不开启滚动
          // 滚动的定时器
          
          if(this.isScrollBlack){
            this.deviceScrollFnBlack();
          }
        })
      }
  });
},
deviceTableEnterBlack() {
  if(this.blackData.length<6){
    return;
  }
  // 手标移入。自动滚动取消.显示滚动条
  this.isScrollBlack = false;
  // 滚动的定时器
  if(this.deviceScorllTimerBlack){
    clearInterval(this.deviceScorllTimerBlack);
    this.deviceScorllTimerBlack = null;
  }
  // 三秒开启滚动的定时器
  if(this.threeMinuteStartBlack){
    clearInterval(this.threeMinuteStartBlack);
    this.threeMinuteStartBlack = null;
  }
  
  this.$nextTick(() => {
      const itemHeight =
          this.$refs["rankitemBlack"][0].offsetHeight;
      // 滚动的次数
      const scrollCnt = this.deviceItemTopBlack / -itemHeight;
      // 当前显示
      let scrollCurrentIndex =
          scrollCnt > this.blackData.length
              ? Number(
                    Math.round(scrollCnt / this.blackData) + 1
                )
              : Number(Math.round(scrollCnt) + 1);
      const maxLength = this.blackData.length;
      let scrollTop = 0 ;
      // 如果当前滚动的是在整个列表的倒数第4个
      if (
          scrollCurrentIndex >= maxLength -5  &&
          scrollCurrentIndex < maxLength-2
      ) {
        scrollTop = (maxLength - 6)  * itemHeight;
          this.$nextTick(() => {
              this.$refs["ranklistBlack"].scrollTop = scrollTop;
          });
      } else if (scrollCurrentIndex == 1 || scrollCurrentIndex>=maxLength-2) {
          this.$refs["ranklistBlack"].scrollTop = 0;
      } else {
          scrollTop = (scrollCurrentIndex - 1) * itemHeight;
          this.$nextTick(() => {
              this.$refs["ranklistBlack"].scrollTop = scrollTop;
          });
      }
  });
},
deviceTableLeaveBlack() {
  if(this.blackData.length<6){
    return;
  }
  if(this.threeMinuteStartBlack){
    return;
  }
  // 手标移出 三秒开启滚动
  this.threeMinuteStartBlack = setTimeout(()=>{
    this.currentTableTopBlack = this.$refs["ranklistBlack"].scrollTop;
    this.isScrollBlack = true;
    this.blackDataScroll = JSON.parse(JSON.stringify(this.blackData));
    this.$nextTick(()=>{
      this.deviceItemTopBlack = -this.currentTableTopBlack;
      this.transtionDetalyBlack = '0s';
      this.deviceScrollFnBlack();
    })
  },3000)
},
// 智慧设备概览滚动方法
deviceScrollFnBlack(){
this.deviceScorllTimerBlack = setInterval(() => {
    this.transtionDetalyBlack = "0.5s";
    // 滚动到最后三个，需要将数据放置尾部。实现首尾相连
    this.$nextTick(() => {
        const itemHeight =
            this.$refs["rankitemBlackScroll"][0].offsetHeight;
        const length = this.blackDataScroll.length;
        const maxScorllLength = length - 6;
        if (this.deviceItemTopBlack <= (maxScorllLength) * -itemHeight) {
          this.transtionDetalyBlack = "0s";
          this.deviceItemTopBlack = 0;
          setTimeout(() => {
              // this.deviceItemTopRed = -itemHeight;
              this.transtionDetalyBlack = "0.5s";
          }, 20);

        }else{
          this.deviceItemTopBlack -= itemHeight;
        }
        // if (this.deviceItemTopBlack <= maxScorllLength * -itemHeight) {
        //     this.blackDataScroll.push(...this.blackData);
        // }
        // const currentIndex = Math.round(
        //     this.deviceItemTopBlack / -itemHeight
        // );
        // // 清空数据。
        // if (currentIndex == this.blackData.length+1) {
        //     this.blackDataScroll.splice(
        //         0,
        //         this.blackData.length
        //     );
        //     this.transtionDetalyBlack = "0s";
        //     this.deviceItemTopBlack = 0;
        //     setTimeout(() => {
        //         this.deviceItemTopBlack = -itemHeight;
        //         this.transtionDetalyBlack = "0.5s";
        //     }, 20);
        // }
    });
}, 2000);

},

// 实时巡查情况
getCheckRecordOnTimeData() {
  this.onTimeData = [];
  this.onTimeDataScroll = [];
  this.deviceItemTopTime = 0;
  this.transtionDetalyTime = '0s'
  if(this.deviceScorllTimerTime){
    clearInterval(this.deviceScorllTimerTime);
    this.deviceScorllTimerTime = null;
  }
  // 三秒开启滚动的定时器
  if(this.threeMinuteStartTime){
    clearInterval(this.threeMinuteStartTime);
    this.threeMinuteStartTime = null;
  }
  checkRecordOnTime().then((res) => {
    // checkWay = 10 checkType 1 督导巡检 2 食堂自检 3 任务巡检
    // checkWay = 10 checkType 1 视频巡检
    
    res.data.forEach((e) => {
      if (e.checkWay == 10) {
        if (e.checkType == 1) {
          e.checkTypeName = "督导巡检";
        } else if (e.checkType == 2) {
          e.checkTypeName = "食堂自检";
        } else if (e.checkType == 3) {
          e.checkTypeName = "任务巡检";
        }
      } else {
        if (e.checkType == 1) {
          e.checkTypeName = "视频巡检";
        }
      }
      e.checkTime = dayjs(e.createTime).format("M/DD HH:mm");
    });
    this.onTimeData = res.data;
    this.onTimeDataScroll = JSON.parse(JSON.stringify(this.onTimeData));
    
    if(this.onTimeData.length>=5){
      this.$nextTick(()=>{
        this.deviceItemTopTime = 0;
        // 滚动的定时器
        
        if(this.isScrollTime){
          this.deviceScrollFnTime();
        }
      })
    }
  });
},
deviceTableEnterTime() {
  if(this.onTimeData.length<5){
    return;
  }
  // 手标移入。自动滚动取消.显示滚动条
  this.isScrollTime = false;
  // 滚动的定时器
  if(this.deviceScorllTimerTime){
    clearInterval(this.deviceScorllTimerTime);
    this.deviceScorllTimerTime = null;
  }
  // 三秒开启滚动的定时器
  if(this.threeMinuteStartTime){
    clearInterval(this.threeMinuteStartTime);
    this.threeMinuteStartTime = null;
  }
  
  this.$nextTick(() => {
      const itemHeight =
          this.$refs["timeitem"][0].offsetHeight;
      // 滚动的次数
      const scrollCnt = this.deviceItemTopTime / -itemHeight;
      // 当前显示
      let scrollCurrentIndex =
          scrollCnt > this.onTimeData.length
              ? Number(
                    Math.round(scrollCnt / this.onTimeData) + 1
                )
              : Number(Math.round(scrollCnt) + 1);
      const maxLength = this.onTimeData.length;
      let scrollTop = 0 ;
      if (
        scrollCurrentIndex >= maxLength -4  &&
        scrollCurrentIndex < maxLength-3
      ) {
        scrollTop = (maxLength - 5)  * itemHeight;
          this.$nextTick(() => {
              this.$refs["timelist"].scrollTop = scrollTop;
          });
      } else if (scrollCurrentIndex == 1 || scrollCurrentIndex>=maxLength-3) {
          this.$refs["timelist"].scrollTop = 0;
      } else {
          scrollTop = (scrollCurrentIndex - 1) * itemHeight;
          this.$nextTick(() => {
              this.$refs["timelist"].scrollTop = scrollTop;
          });
      }
  });
},
deviceTableLeaveTime() {
  if(this.onTimeData.length<5){
    return;
  }
  if(this.threeMinuteStartTime){
    return;
  }
  // 手标移出 三秒开启滚动
  this.threeMinuteStartTime = setTimeout(()=>{
    this.currentTableTopTime = this.$refs["timelist"].scrollTop;
    this.isScrollTime = true;
    this.onTimeDataScroll = JSON.parse(JSON.stringify(this.onTimeData));
    this.$nextTick(()=>{
      this.deviceItemTopTime = -this.currentTableTopTime;
      this.transtionDetalyTime = '0s';
      this.deviceScrollFnTime();
    })
  },3000)
},
// 智慧设备概览滚动方法
deviceScrollFnTime(){
this.deviceScorllTimerTime = setInterval(() => {
    this.transtionDetalyTime = "0.5s";
    // 滚动到最后三个，需要将数据放置尾部。实现首尾相连
    this.$nextTick(() => {
        const itemHeight =
            this.$refs["timeitemScroll"][0].offsetHeight;
        this.deviceItemTopTime -= itemHeight;
        const length = this.onTimeDataScroll.length;
        const maxScorllLength = length - 5;
        if (this.deviceItemTopTime <= maxScorllLength * -itemHeight) {
            this.onTimeDataScroll.push(...this.onTimeData);
        }
        const currentIndex = Math.round(
            this.deviceItemTopTime / -itemHeight
        );
        // 清空数据。
        if (currentIndex == this.onTimeData.length+1) {
            this.onTimeDataScroll.splice(
                0,
                this.onTimeData.length
            );
            this.transtionDetalyTime = "0s";
            this.deviceItemTopTime = 0;
            setTimeout(() => {
                this.deviceItemTopTime = -itemHeight;
                this.transtionDetalyTime = "0.5s";
            }, 20);
        }
    });
}, 2000);
},


    getShopScoreList() {
      // shopScoreDetailPage(this.shopScoreParams).then((res) => {
      //   this.safeScoreList = res.data.records;
      //   if (this.safeScoreList.length > 0) {
      //     const nodataList = Array(10 - this.safeScoreList.length).fill({ fillFlag: true });
      //     this.safeScoreList = this.safeScoreList.concat(nodataList);
      //   }
      //   this.total = res.data.total;
      // });
    },
    chooseShopRIskHead(item, index) {
      this.queryParams.current = 1;

      this.queryParams.type = item.type == 1 ? 1 : 0;

      this.xjheadList.forEach((e) => {
        e.isChoose = false;
      });
      item.isChoose = true;
      this.$set(this.xjheadList, index, item);
      this.getxjTable();
    },
    showXujianDia(type) {
      this.queryParams.current = 1;
      if (type == 1) {
        this.xjLabel = "督查巡检详情";
        this.queryParams.checkWay = 10;
        this.queryParams.type = 1;

        xjHead({ checkWay: 10 }).then((res) => {
          this.xjheadList = [
            {
              label: "已巡检",
              num: res.data.checkShops,
              type: 1,
              isChoose: true,
            },
            {
              label: "未巡检",
              num: res.data.unCheckShops,
              type: 2,
              isChoose: false,
            },
          ];
        });
        this.getxjTable();
      } else {
        this.xjLabel = "视频巡检详情";
        this.queryParams.checkWay = 20;
        this.queryParams.type = 1;

        xjHead({ checkWay: 20 }).then((res) => {
          this.xjheadList = [
            {
              label: "已巡检",
              num: res.data.checkShops,
              type: 1,
              isChoose: true,
            },
            {
              label: "未巡检",
              num: res.data.unCheckShops,
              type: 2,
              isChoose: false,
            },
          ];
        });
        this.getxjTable();
      }
      this.xjDiaShow = true;
    },
    // 获取巡检表格
    getxjTable() {
      this.tableLoading = true;
      xjTable(this.queryParams).then((res) => {
        this.xjTableList = res.data || [];
        // if (this.xjTableList.length > 0 && this.xjTableList.length < 10) {
        //   const nodataList = Array(10 - this.xjTableList.length).fill({ fillFlag: true });
        //   this.xjTableList = this.xjTableList.concat(nodataList);
        // }
        this.total = this.xjTableList.length;
        this.tableLoading = false;
      });
    },
    
    ruleChange(e) {
      this.ruleId = e;
      this.getRulesTableList(e);
    },
    getRuleList() {
      rulesList().then((res) => {
        if (res.code == 200) {
          this.ruleList = res.data;
          this.ruleId = res.data[0].logRuleId;
          this.getRulesTableList(res.data[0].logRuleId);
        }
      });
    },
    requestList() {
      this.getRuleList();
      // 后勤指挥巡检数据
      this.getLogisticsCommandData();
      // 本月食堂巡检进度
      this.getInspectionProgressData();
      // 本月整改分布 --- 雷达图
      this.getRectifyRadarData();
      // 本月整改分布 --- 整改统计
      this.getRectifyStatisticData()
      // 本月食堂巡检分析
      this.getInspectionAnalyseData();
      // 重点提升方向左侧
      this.getImprovementTableData();
      // 南丁格尔
      this.getImprovementNightTingData();
      // 红榜
      this.getRedData();
      // 黑榜
      this.getBlackData();
      // 实时巡查情况
      this.getCheckRecordOnTimeData();
    },
    scoreDetailShopClick(item, index) {
      this.queryParams.current = 1;
      this.queryParams.shopId = item.shopId;
      this.safeScoreShopList.forEach((e) => {
        e.isChoose = false;
      });
      item.isChoose = true;
      this.$set(this.safeScoreShopList, index, item);
      this.balckPage(1);
      // this.shopScoreParams.shopId = item.shopId;
      // this.shopScoreParams.current = 1;
      // this.shopScoreParams.grade = 1;
      // this.getShopScoreList();
      // this.getScoreTop();
    },
    // 后勤指挥巡检数据
    getLogisticsCommandData() {
      this.showFilpper = false;
      logisticsCommand().then((res) => {
        if (this.flipperTimer) {
          clearInterval(this.flipperTimer);
          this.flipperTimer = null;
        }
        this.logisticsData = res.data;
        if (!this.logisticsData.checkCount) {
          this.logisticsData.checkCount = 0;
        }
        this.showFilpper = true;
        this.flipperTimer = setInterval(() => {
          this.showFilpper = false;
          setTimeout(() => {
            this.showFilpper = true;
          });
        }, 30000);
      });
    },
    // 本月食堂巡检进度
    getInspectionProgressData() {
      inspectionProgress().then((res) => {
        const data = res.data;
        this.inspectionProgressData.offLineCount = data.offLineCount;
        this.inspectionProgressData.onLineCount = data.onLineCount;
        this.inspectionProgressData.shopCount = data.shopCount;
        const offLineCountNoCheck = data.shopCount - data.offLineCount;
        const onLineCountNoCheck = data.shopCount - data.onLineCount;
        const offLineRate = Math.round((data.offLineCount / data.shopCount) * 100);
        const onLineRate = Math.round((data.onLineCount / data.shopCount) * 100);
        const offLineSeriesData = this.dealProgressPieData(data.offLineCount, offLineCountNoCheck, "offLine");
        const onLineSeriesData = this.dealProgressPieData(data.onLineCount, onLineCountNoCheck, "onLine");
        this.$nextTick(() => {
          this.$refs.taskPie.initChart(offLineSeriesData, offLineRate + "%", "督导巡检");
          this.$refs.taskPieVideo.initChart(onLineSeriesData, onLineRate + "%", "视频巡检");

          if (this.progressTimer) {
            clearInterval(this.progressTimer);
            this.progressTimer = null;
          }
          this.progressTimer = setInterval(() => {
            this.$refs.taskPie.initChart(offLineSeriesData, offLineRate + "%", "督导巡检");
            this.$refs.taskPieVideo.initChart(onLineSeriesData, onLineRate + "%", "视频巡检");
          }, 60000);
        });
      });
    },
    dealProgressPieData(complete, inComplete, type) {
      return [
        {
          type: "pie",
          radius: ["80%", "95%"],
          center: ["50%", "50%"],
          color: type === "offLine" ? ["#318EFF", "#C8D5EB"] : ["#4FDC5D", "#C8D5EB"],
          label: {
            show: false,
          },
          itemStyle: {
            borderColor: "#000",
            borderWidth: 4 * this.widthPix,
          },
          data: [
            { value: complete, name: "已完成" },
            { value: inComplete, name: "未完成" },
          ],
        },
        {
          type: "pie",
          radius: ["55%", "80%"],
          center: ["50%", "50%"],
          label: {
            show: false,
          },
          itemStyle: {
            borderColor: "#000",
            borderWidth: 4 * this.widthPix,
          },
          data: [
            {
              value: complete,
              name: "已完成",
              itemStyle: {
                color: type === "offLine" ? "rgba(84, 155, 241, 0.28)" : "rgba(60, 187, 73, 0.3)",
              },
            },
            {
              value: inComplete,
              name: "未完成",
              itemStyle: {
                color: "rgba(146, 166, 198, 0.3)",
              },
            },
          ],
        },
      ];
    },
    // 本月整改分布
    // 雷达图
    getRectifyRadarData() {
      this.isDzgClick = true;
      rectifyRadar().then((res) => {
        const data = res.data;
        this.rectifyRadarData.completeCount = data.completeCount;
        this.rectifyRadarData.undoCount = data.undoCount;
        this.rectifyRadarData.failCount = data.failCount;
        this.rectifyRadarData.againCount = data.againCount;
        this.rectifyRadarData.unVerifyCount = data.unVerifyCount;
        this.rectifyRadarData.rectifyRadarCount = data.completeCount + data.undoCount + data.failCount + data.againCount + data.unVerifyCount;
        const indicator = [
          { name: "整改成功 " + data.completeCount, max: this.rectifyRadarData.rectifyRadarCount },
          { name: "待整改 " + data.undoCount, max: this.rectifyRadarData.rectifyRadarCount },
          { name: "整改失败 " + data.failCount, max: this.rectifyRadarData.rectifyRadarCount },
          { name: "重新整改 " + data.againCount, max: this.rectifyRadarData.rectifyRadarCount },
          { name: "待审核 " + data.unVerifyCount, max: this.rectifyRadarData.rectifyRadarCount },
        ];
        const radarData = [data.completeCount, data.undoCount, data.failCount, data.againCount, data.unVerifyCount];
        const radarOutData = Array(5).fill(this.rectifyRadarData.rectifyRadarCount);
        this.$nextTick(() => {
          this.$refs.radar.initChart(indicator, radarData, radarOutData);

          if (this.radarTimer) {
            clearInterval(this.radarTimer);
            this.radarTimer = null;
          }
          this.radarTimer = setInterval(() => {
            this.$refs.radar.initChart(indicator, radarData, radarOutData);
          }, 60000);
        });
      });
    },
    dzgClick2(item) {
      this.dzgDiaShow = item.isShow;
      shopTopBlack2().then((res) => {
        this.safeScoreShopList = res.data.sort((a, b) => (Number(a.problemCount) < Number(b.problemCount) ? 1 : -1));
        const problemCount = this.safeScoreShopList.map((e) => Number(e.problemCount));
        const totalProblemCount = problemCount.reduce((pre, next) => pre + next);
        this.safeScoreShopList.forEach((e) => {
          const score = Number(e.problemCount);
          e.rate = Math.round((score / totalProblemCount) * 100) + "%";
        });

        this.safeScoreShopList.forEach((e) => {
          e.isChoose = false;
        });
        this.safeScoreShopList[0].isChoose = true;
        this.queryParams.shopId = this.safeScoreShopList[0].shopId;
        this.queryParams.current = 1;
        this.balckPage(1);
      });
    },
    blackDetailClick(item, index) {
      this.queryParams.current = 1;
      this.queryParams.shopId = item.shopId;
      this.safeScoreList = [];
      this.scoreChooseList.forEach((e) => {
        e.isChoose = false;
      });
      item.isChoose = true;
      this.$set(this.scoreChooseList, index, item);
      this.balckPage();
      this.safeScoreShow = true;
    },
    chooseScoreIt(item, index) {
      this.queryParams.current = 1;
      this.queryParams.shopId = item.shopId;
      this.scoreChooseList.forEach((e) => {
        e.isChoose = false;
      });
      item.isChoose = true;
      this.$set(this.scoreChooseList, index, item);
      this.balckPage();
    },
    async balckPage(type=0) {
      this.tableLoading = true;
      this.safeScoreList = [];
      if(type == 0){
        this.queryParams.size = 10;
        this.queryParams.type = '';
      }else{
        this.queryParams.size = 9999;
        this.queryParams.type = 3;
      }
      await blackDetail(this.queryParams).then((res) => {
        this.safeScoreList = res.data.records || [];
        if (this.safeScoreList.length > 0 && this.safeScoreList.length < 10) {
          const nodataList = Array(10 - this.safeScoreList.length).fill({ fillFlag: true });
          this.safeScoreList = this.safeScoreList.concat(nodataList);
        }
        this.total = res.data.total;
        this.tableLoading = false;
      });
    },
    
    
    // 重点提升方向左侧
    getImprovementTableData() {
      improvementTable().then((res) => {
        const data = res.data;
        data.forEach((e, index) => {
          e.index = index + 1;
        });
        this.improvementData = [];
        // for (let i = 0; i < e.length; i += 2) {
        //     swiperList.push(e.slice(i, i + 2));
        //   }
        const loopNum = Math.ceil(data.length / 3); // 四次一页
        for (let index = 0; index < loopNum; index++) {
          this.improvementData.push(data.splice(0, 3));
        }
      });
    },
    // 南丁格尔
    getImprovementNightTingData() {
      improvementNightTing().then((res) => {
        const pieColors = ["rgba(84, 155, 241, 0.28)", "rgba(49, 241, 255, 0.28)", "rgba(79, 220, 93, 0.28)", "rgba(255, 121, 87, 0.28)", "rgba(255, 205, 88, 0.28)", "rgba(161, 174, 195, 0.28)", "(161, 174, 195, 0.13)", "(161, 174, 195, 0.08)"];
        let data = res.data.length>0?res.data:[
          {
            index: 8,
            topicType: "其他",
            appearAccount: 0,
            rate: "0%",
          }
        ];
        data = data.sort((a, b) => (a.appearAccount > b.appearAccount ? -1 : 1));
        const nums = data.map((e) => e.appearAccount);
        const total = nums.reduce((pre, next) => pre + next);
        const seriesData = [];
        const borderColor = ["#318EFF", "#31F1FF", "#4FDC5D", "#FF7957", "#FFCD58", "#A1AEC3", "rgba(161, 174, 195, 0.6)", "rgba(161, 174, 195, 0.34)"];
        data.forEach((e, index) => {
          e.rate = ((e.appearAccount / total) * 100).toFixed(1) + "%";

          const pieObj = {
            value: e.appearAccount,
            itemStyle: {
              color: pieColors[index],
              borderWidth: this.widthPix * 2,
              borderColor: borderColor[index],
            },
          };
          seriesData.push(pieObj);
        });
        this.improvementChartDescData = data;
        const series = [
          {
            type: "pie",
            radius: [0, "90%"],
            center: ["50%", "50%"],
            roseType: "radius",
            label: {
              show: false,
            },
            data: seriesData,
          },
        ];
        setTimeout(() => {
          this.$refs.improvementPie.initChart(series);
          if (this.nightTingTimer) {
            clearInterval(this.nightTingTimer);
            this.nightTingTimer = null;
          }
          this.nightTingTimer = setInterval(() => {
            this.$refs.improvementPie.initChart(series);
          }, 60000);
        }, 200);
      });
    },
   
    
    
  },
  mounted() {
    this.widthPix = window.screen.width < 1920 ? 1 : Math.floor(window.screen.width / 1920);
    this.$refs.taskPie.initChart(0, 0 + "%", "督导巡检");
    this.$refs.taskPieVideo.initChart(0, 0 + "%", "视频巡检");
    const indicator = [
      { name: "整改成功 " + 0, max: 0 },
      { name: "待整改 " + 0, max: 0 },
      { name: "整改失败 " + 0, max: 0 },
      { name: "重新整改 " + 0, max: 0 },
      { name: "待审核 " + 0, max: 0 },
    ];
    this.$refs.radar.initChart(indicator, [0, 0, 0, 0, 0], [0, 0, 0, 0, 0]);
    const category = ["A区一食堂", "A区二食堂", "A区三食堂", "A区四食堂", "A区五食堂"];
    const series = [
      {
        name: "督导巡检",
        type: "bar",
        showBackground: true,
        backgroundStyle: {
          color: "rgba(43, 62, 81, 0.6)",
        },
        barWidth: 10 * this.widthPix,

        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#83bff6" },
            { offset: 0.5, color: "#4F8DFF" },
            { offset: 1, color: "#4F8DFF" },
          ]),
        },
        data: [0, 0, 0, 0, 0],
      },
      {
        name: "视频巡检",
        type: "bar",
        showBackground: true,
        backgroundStyle: {
          color: "rgba(43, 62, 81, 0.6)",
        },
        barWidth: 10 * this.widthPix,

        barGap: 1,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#fff" },
            { offset: 0.5, color: "#64C56E" },
            { offset: 1, color: "#64C56E" },
          ]),
        },
        data: [0, 0, 0, 0, 0, 0, 0, 0],
      },
    ];
    this.$refs.scoreLine.initChart(series, category);

    const data = [
      {
        index: 1,
        topicType: "卫生情况",
        appearAccount: 0,
        rate: "0%",
      },
      {
        index: 2,
        topicType: "卫生情况",
        appearAccount: 0,
        rate: "0%",
      },
      {
        index: 3,
        topicType: "卫生情况",
        appearAccount: 0,
        rate: "0%",
      },
      {
        index: 4,
        topicType: "卫生情况",
        appearAccount: 0,
        rate: "0%",
      },
    ];
    const pieColors = ["rgba(84, 155, 241, 0.28)", "rgba(49, 241, 255, 0.28)", "rgba(79, 220, 93, 0.28)", "rgba(255, 121, 87, 0.28)", "rgba(255, 205, 88, 0.28)", "rgba(161, 174, 195, 0.28)", "(161, 174, 195, 0.13)", "(161, 174, 195, 0.08)"];
    const seriesData = [];
    const borderColor = ["#318EFF", "#31F1FF", "#4FDC5D", "#FF7957", "#FFCD58", "#A1AEC3", "rgba(161, 174, 195, 0.6)", "rgba(161, 174, 195, 0.34)"];
    data.forEach((e, index) => {
      e.rate = Math.round((e.appearAccount / 0) * 100) + "%";

      const pieObj = {
        value: e.appearAccount,
        itemStyle: {
          color: pieColors[index],
          borderWidth: this.widthPix * 2,
          borderColor: borderColor[index],
        },
      };
      seriesData.push(pieObj);
    });
    this.improvementChartDescData = data;
    const seriesNing = [
      {
        type: "pie",
        radius: [0, "90%"],
        center: ["50%", "50%"],
        roseType: "radius",
        label: {
          show: false,
        },
        data: seriesData,
      },
    ];
    this.$nextTick(() => {
      this.$refs.improvementPie.initChart(seriesNing);
    });

    // this.getRectifyStatisticData();
    this.requestList();
    this.timer = setInterval(() => {
      this.requestList();
    }, 300000);
    // this.timer2 = setInterval(() => {
    //   this.getRectifyStatisticData();
    // }, 120000);
  },
  activated() {
    if (!this.timer) {
      this.requestList();
      this.timer = setInterval(() => {
        this.requestList();
      }, 300000);
    }
    // if (!this.timer2) {
    //   this.timer2 = setInterval(() => {
    //     this.getRectifyStatisticData();
    //   }, 120000);
    // }
  },
  deactivated() {
    clearInterval(this.timer);
    clearInterval(this.timer2);
    clearInterval(this.progressTimer);
    clearInterval(this.radarTimer);
    clearInterval(this.analyseTimer);
    clearInterval(this.nightTingTimer);
    clearInterval(this.flipperTimer);
    clearInterval(this.redTimer);
    clearInterval(this.blackTimer);
    this.timer = null;
    this.timer2 = null;
    this.progressTimer = null;
    this.radarTimer = null;
    this.analyseTimer = null;
    this.nightTingTimer = null;
    this.flipperTimer = null;
    this.redTimer = null;
    this.blackTimer = null;
  },
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.timer2);
    clearInterval(this.progressTimer);
    clearInterval(this.radarTimer);
    clearInterval(this.analyseTimer);
    clearInterval(this.nightTingTimer);
    clearInterval(this.flipperTimer);
    clearInterval(this.redTimer);
    clearInterval(this.blackTimer);
    this.timer = null;
    this.timer2 = null;
    this.progressTimer = null;
    this.radarTimer = null;
    this.analyseTimer = null;
    this.nightTingTimer = null;
    this.flipperTimer = null;
    this.redTimer = null;
    this.blackTimer = null;
    if(this.deviceScorllTimerRule){
      clearInterval(this.deviceScorllTimerRule);
      this.deviceScorllTimerRule = null;
    }
    // 三秒开启滚动的定时器
    if(this.threeMinuteStartRule){
      clearInterval(this.threeMinuteStartRule);
      this.threeMinuteStartRule = null;
    }
    if(this.deviceScorllTimerMonth){
      clearInterval(this.deviceScorllTimerMonth);
      this.deviceScorllTimerMonth = null;
    }
    // 三秒开启滚动的定时器
    if(this.threeMinuteStartMonth){
      clearInterval(this.threeMinuteStartMonth);
      this.threeMinuteStartMonth = null;
    }
    if(this.deviceScorllTimerRectify){
      clearInterval(this.deviceScorllTimerRectify);
      this.deviceScorllTimerRectify = null;
    }
    // 三秒开启滚动的定时器
    if(this.threeMinuteStartRectify){
      clearInterval(this.threeMinuteStartRectify);
      this.threeMinuteStartRectify = null;
    }
    if(this.deviceScorllTimerRed){
      clearInterval(this.deviceScorllTimerRed);
      this.deviceScorllTimerRed = null;
    }
    // 三秒开启滚动的定时器
    if(this.threeMinuteStartRed){
      clearInterval(this.threeMinuteStartRed);
      this.threeMinuteStartRed = null;
    }
    if(this.deviceScorllTimerBlack){
      clearInterval(this.deviceScorllTimerBlack);
      this.deviceScorllTimerBlack = null;
    }
    // 三秒开启滚动的定时器
    if(this.threeMinuteStartBlack){
      clearInterval(this.threeMinuteStartBlack);
      this.threeMinuteStartBlack = null;
    }
    if(this.deviceScorllTimerTime){
      clearInterval(this.deviceScorllTimerTime);
      this.deviceScorllTimerTime = null;
    }
    // 三秒开启滚动的定时器
    if(this.threeMinuteStartTime){
      clearInterval(this.threeMinuteStartTime);
      this.threeMinuteStartTime = null;
    }
  },
};
