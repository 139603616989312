<template>
  <div :style="{ width: width, height: height, position: 'relative' }">
    <img src="https://dobaycdn.anasit.com/dashboard_images/title.png" alt="" :style="{ width: width, height: height }" />
    <div class="text-left">{{ textLeft }}</div>
    <div class="text-right">
      <span class="text-right1">{{ textRight1 }}</span
      ><span class="text-right2">
        <slot></slot>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      default: "100%",
    },
    height: {
      default: "0.1563rem",
    },
    textLeft: {
      default: "左侧文字",
    },
    textRight1: {
      default: "",
    },
    textRight2: {
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.text-left {
  position: absolute;
  left: 0.1763rem;
  top: 50%;
  transform: translate(0, -50%);
  color: white;

  font-family: "AlibabaPuHuiTi";
  font-size: 0.0729rem;
  font-weight: 600;
  letter-spacing: 0em;

  background: linear-gradient(180deg, #ffffff 19%, #64ceff 89%), #d9eaf5;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0px 3px 1px 0px rgba(0, 0, 0, 0.3), 0px 0px 7px 0px rgba(112, 199, 255, 0.69);
}
.text-right {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  color: white;
  display: flex;
  align-items: center;
  .text-right1 {
    font-size: 0.0625rem;
    color: #bcd2ed;
    margin-right: 0.0313rem;
  }
  .text-right2 {
    font-size: 0.0625rem;
    color: #ffffff;
    font-size: 600;
  }
}
</style>
